/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { onError } from '@apollo/client/link/error';
import axios from 'src/utils/axios';
import { GRAPHQL_URL, UMESH_API_DOMAIN, APP_VERSION } from 'src/config';
import { useAuth } from 'src/components/uAuth';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

const GRAPHENE_HTTP_URL = `${window.location.protocol}//${UMESH_API_DOMAIN}/graphql`;
const STRAWBERRY_HTTP_URL = `${window.location.protocol}//${UMESH_API_DOMAIN}/strawberry`;
const STRAWBERRY_WS_URL =
  window.location.protocol === 'http:'
    ? `ws://${UMESH_API_DOMAIN}/strawberry`
    : `wss://${UMESH_API_DOMAIN}/strawberry`;

export default function CustomApolloProvider({ children }) {
  const { authService } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = authService.getAuthTokens();
  const user = useSelector((state) => state.account.user);

  const login = (key) => (
    <Fragment>
      <Button
        onClick={() => {
          window.location = '/login';
        }}
      >
        Login
      </Button>
    </Fragment>
  );

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (networkError.statusCode === 401) {
        // graphene will return  401 if token is invalid
        enqueueSnackbar('Unauthorized!', { variant: 'error', action: login });
      } else {
        if (networkError.message?.toLowerCase() === 'failed to fetch') {
          Sentry.withScope((scope) => {
            scope.setLevel('debug');
            scope.setUser({ id: user?.id });
            Sentry.captureException(networkError, {
              extra: {
                operation: operation,
              },
            });
          });

        }

        enqueueSnackbar(`Error ${networkError.message}!`, {
          variant: 'error',
        });
      }
    }
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      for (let err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case 'access-denied': {
            // hasura returns gql error with 'access-denied' code if token is invalid
            enqueueSnackbar('Unauthorized!', {
              variant: 'error',
              action: login,
            });
            return;
          }
        }
      }
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const { token } = authService.getAuthTokens();
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        'app-version': `umesh-ui@${APP_VERSION}`,
      },
    }));
    return forward(operation);
  });

  const hasuraLink = new HttpLink({ uri: GRAPHQL_URL });
  const grapheneLink = createUploadLink({ uri: GRAPHENE_HTTP_URL });
  const strawberryHttpLink = new HttpLink({ uri: STRAWBERRY_HTTP_URL });
  const strawberryWsLink = new GraphQLWsLink(
    createClient({ url: `${STRAWBERRY_WS_URL}?token=${token}` })
  );

  const splitLink = ApolloLink.split(
    // if clientName == graphene, use graphene http endpoint
    ({ getContext }) => getContext().clientName == 'graphene',
    grapheneLink,
    ApolloLink.split(
      // if clientName == strawberry and is subscription, use stawberry ws endpoint
      ({ getContext, query }) => {
        const definition = getMainDefinition(query);
        return (
          getContext().clientName == 'strawberry',
          definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
      },
      strawberryWsLink,
      ApolloLink.split(
        // if clientName == strawberry, use strawberry http endpoint
        ({ getContext }) => getContext().clientName == 'strawberry',
        strawberryHttpLink,
        // default: hasura endpoint
        hasuraLink
      )
    )
  );

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, splitLink]),
    cache: new InMemoryCache(),
  });

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

CustomApolloProvider.propTypes = {
  children: PropTypes.any,
};
