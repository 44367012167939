/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  ListItem,
  Button,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { APP_VERSION, IS_DEV } from 'src/config';
import NavContent from './NavContent';

function NavBar({ open, drawerWidth, onToggle }) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    mobileDrawer: {
      width: drawerWidth,
      justifyContent: 'space-between',
    },
    desktopDrawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    desktopDrawerPaper: {
      justifyContent: 'space-between',
      width: drawerWidth,
      paddingTop: 70,
    },
    desktopDrawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    desktopDrawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(6),
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
    },
    itemLeaf: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    collapseButton: {
      color: theme.palette.text.secondary,
      padding: '10px 12px',
      justifyContent: 'space-between',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
    },
    labelColor: {
      color: theme.palette.breadcrumb?.label,
    },
    collapseButtonExpanded: {
      paddingLeft: '52px',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onToggle(false)}
          open={open}
          variant="temporary"
        >
          <NavContent maximized />
          <Box my={1}>
            <Typography
              variant="h6"
              align="center"
              style={{
                textTransform: 'uppercase',
              }}
            >
              {IS_DEV ? 'Test Environment' : 'Prod Environment'}
            </Typography>
            <Typography variant="body2" align="center">
              Version {APP_VERSION}
            </Typography>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          variant="permanent"
          classes={{
            paper: clsx(classes.desktopDrawerPaper, {
              [classes.desktopDrawerOpen]: open,
              [classes.desktopDrawerClose]: !open,
            }),
            root: clsx(classes.desktopDrawer, {
              [classes.desktopDrawerOpen]: open,
              [classes.desktopDrawerClose]: !open,
            }),
          }}
        >
          <div>
            <NavContent maximized={open} />
            <ListItem className={classes.itemLeaf} disableGutters key="toggle">
              <Button
                className={clsx(classes.collapseButton, {
                  [classes.classescollapseButtonExpanded]: open,
                })}
                onClick={() => {
                  onToggle(!open);
                }}
              >
                {open && <span>Collapse Menu</span>}
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </Button>
            </ListItem>
            <Divider />
          </div>
          {open && (
            <Box my={1}>
              <Typography
                variant="h6"
                align="center"
                className={classes.labelColor}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {IS_DEV ? 'Test Environment' : 'Prod Environment'}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                className={classes.labelColor}
              >
                Version {APP_VERSION}
              </Typography>
            </Box>
          )}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onToggle: PropTypes.func,
  drawerWidth: PropTypes.number.isRequired,
  open: PropTypes.bool,
};

export default NavBar;
