import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ScrollToTop from 'src/components/ScrollToTop';
import ScrollToBottom from 'src/components/ScrollToBottom';
import NavBar from './NavBar';
import TopBar from './TopBar';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 70,
  },
  wrapperDrawerOpen: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: drawerWidth,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function DashboardLayout({ children }) {
  const classes = useStyles();

  const isDrawerOpen = localStorage.getItem('isDrawerOpen') === 'true';
  const [isMobileNavOpen, setMobileNavOpen] = useState(isDrawerOpen);
  const [contentElement, setContentElement] = useState(null);

  function toggleDrawer(value) {
    localStorage.setItem('isDrawerOpen', value);
    setMobileNavOpen(value);
  }

  const contentRef = React.useCallback((node) => {
    if (node) {
      setContentElement(node);
    }
  }, []);

  return (
    <div className={classes.root}>
      <TopBar open={isMobileNavOpen} onDrawerToggle={(v) => toggleDrawer(v)} />
      <NavBar
        drawerWidth={drawerWidth}
        onToggle={(v) => toggleDrawer(v)}
        open={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} ref={contentRef}>
            {children}
            {contentElement && (
              <>
                <ScrollToTop element={contentElement} />
                <ScrollToBottom element={contentElement} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
