/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import light from './light';
import dark from './dark';
import typography from './typography';
import { COLORS } from '../constants';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiButton: {
      root: {
        padding: '6px 10px',
        '& .MuiSvgIcon-root': {
          marginRight: '6px',
        },
      },
    },
  },
  palette: {
    colors: {
      midnight: COLORS.MIDNIGHT,
      porcelain: COLORS.PORCELAIN,
      sky: COLORS.SKY,
      golden: COLORS.GOLDEN,
      mint: COLORS.MINT,
      chilli: COLORS.CHILLI,
      white: COLORS.WHITE,
      darkgrey: COLORS.DARK_GREY,
      midnight_light: COLORS.MIDNIGHT_LIGHT,
      porcelain_dark: COLORS.PORCELAIN_DARK,
    },
  },
};

const themeConfigs = [light, dark];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
