import { styled } from '@material-ui/core';
import DefaultNotistackContent from './DefaultNotistackContent';

const SuccessNotistackContent = styled(DefaultNotistackContent)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  })
);

export default SuccessNotistackContent;
