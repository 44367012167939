export const toSnakeCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
export const toUrlEncoded = (obj) =>
  Object.keys(obj)
    .map(
      (k) =>
        `${encodeURIComponent(toSnakeCase(k))}=${encodeURIComponent(obj[k])}`
    )
    .join('&');
