/* eslint-disable camelcase */
import React, { useRef, useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from 'clsx';
import { X as XIcon } from 'react-feather';
import { Waypoint } from 'react-waypoint';
import { isToday, isYesterday, format, parseISO } from 'date-fns';

import { COLORS } from 'src/constants';
import { WAYPOINT_ICON_COMPONENT } from 'src/constants/icons';
import useNotifications from './useNotifications';

const formatNotificationDateTime = (value) => {
  const dateObj = parseISO(value);
  if (isToday(dateObj)) return format(dateObj, "'Today' HH:mm");
  if (isYesterday(dateObj)) return format(dateObj, "'Yesterday' HH:mm");
  return format(dateObj, "dd/MM/yy HH:mm'");
};

const useTileStyles = makeStyles((theme) => ({
  unreadListItem: {
    color: theme.palette.text.primary,
  },
  readListItem: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey,
  },
  listItem: {
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  container: {
    borderLeft: ({ read }) => `5px solid ${read ? 'grey' : 'palevioletred'}`,
    display: 'grid',
    gridTemplateRows: '20px min-content auto',
    gridTemplateColumns: '60px auto auto',
    width: '100%',
    minHeight: '80px',
  },
  icon: {
    gridRow: '1/4',
    gridColumn: '1/2',
    justifySelf: 'center',
    alignSelf: 'center',
    fontSize: '2rem',
  },
  title: {
    gridRow: '2/3',
    gridColumn: '2/3',
  },
  content: {
    gridRow: '3/4',
    gridColumn: '2/3',
    alignSelf: 'start',
  },
  clearBtn: {
    gridRow: '1/2',
    gridColumn: '3/4',
    justifySelf: 'end',
    alignSelf: 'start',
    borderRadius: '2px',
    color: 'inherit',
  },
  timestamp: {
    gridRow: '2/3',
    gridColumn: '3/4',
    justifySelf: 'end',
  },
  actionsBtn: {
    gridRow: '3/4',
    gridColumn: '3/4',
    justifySelf: 'end',
    borderRadius: '2px',
    color: 'inherit',
  },
}));

const NotificationActionItem = React.forwardRef(
  ({ title = '', type, content }, ref) => {
    const location = useLocation();
    title = title.toUpperCase(); // eslint-disable-line no-param-reassign
    switch (type) {
      case 'redirect':
        return (
          <MenuItem
            ref={ref}
            to={{ pathname: content, state: { from: location.pathname } }}
            component={RouterLink}
          >
            {title}
          </MenuItem>
        );
      case 'download':
        return (
          <MenuItem ref={ref} component="a" href={content} download>
            {title}
          </MenuItem>
        );
      default:
        return <MenuItem ref={ref}>{title}</MenuItem>;
    }
  }
);

NotificationActionItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.string,
};

const iconTypeMapping = {
  files: WAYPOINT_ICON_COMPONENT.FILES_MODULE,
  assets: WAYPOINT_ICON_COMPONENT.ASSETS_MODULE,
  mem: WAYPOINT_ICON_COMPONENT.MEM_MODULE,
  jobs: WAYPOINT_ICON_COMPONENT.JOBS_MODULE,
  users: WAYPOINT_ICON_COMPONENT.USERS_MODULE,
};

export function NotificationTile({
  notification,
  onDelete,
  onRead,
  onUnread,
  onSelect,
  selectMode,
  selected,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openActions = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const closeActions = () => setAnchorEl(null);

  const checkboxRef = useRef(null);
  const clickCheckbox = () => checkboxRef.current?.click();

  const classes = useTileStyles({ read: notification.read });
  const { activity, read } = notification;
  const IconComponent = iconTypeMapping[notification.iconType] || ListAltIcon;
  return (
    <>
      <ListItem
        className={clsx(
          classes.listItem,
          notification.read ? classes.readListItem : classes.unreadListItem
        )}
        style={{ cursor: selectMode ? 'pointer' : undefined }}
        divider
        key={notification.id}
        to="#"
        onClick={clickCheckbox}
      >
        {selectMode && (
          <Checkbox
            inputRef={checkboxRef}
            onChange={onSelect}
            onClick={(e) => e.stopPropagation()}
            checked={selected || false}
            style={{ marginLeft: '-15px' }}
          />
        )}
        <Box className={classes.container}>
          <IconComponent className={classes.icon} fontSize="large" />

          <Typography className={classes.title} variant="subtitle2">
            {activity.name}
          </Typography>

          <Typography
            className={classes.content}
            variant="body2"
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: activity.content }}
          />

          {!selectMode && (
            <IconButton
              className={classes.clearBtn}
              onClick={onDelete}
              title="Delete"
              size="small"
            >
              <XIcon style={{ height: '20px', weight: '20px' }} />
            </IconButton>
          )}

          <Typography
            className={classes.timestamp}
            variant="subtitle2"
            color="textSecondary"
          >
            {formatNotificationDateTime(activity.timestamp)}
          </Typography>

          <IconButton
            className={classes.actionsBtn}
            onClick={openActions}
            title="More Actions"
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </ListItem>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeActions}
        onClick={closeActions}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {notification.actions?.map((action) => (
          <NotificationActionItem key={action.title} {...action} />
        ))}
        {!selectMode &&
          (read ? (
            <MenuItem onClick={onUnread}>MARK AS UNREAD</MenuItem>
          ) : (
            <MenuItem onClick={onRead}>MARK AS READ</MenuItem>
          ))}
      </Menu>
    </>
  );
}

NotificationTile.propTypes = {
  notification: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  onUnread: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectMode: PropTypes.bool,
  selected: PropTypes.bool,
};

const useDropdownStyles = makeStyles((theme) => ({
  popover: {
    width: 475,
    marginTop: 10,
  },
  icon: {
    backgroundColor: theme.palette.background.logo,
    color: COLORS.MIDNIGHT,
    '&:hover': { background: '#f0f0f0' },
    boxShadow: '0px 5px 5px 0px rgb(0 0 0 / 30%);',
    height: 30,
    width: 30,
  },
  iconSvg: {
    margin: 8,
    display: 'block',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  notificationIcon: {
    backgroundColor: theme.palette.notification.background,
    color: theme.palette.notification.color,
  },
}));

function NotificationsDropdown() {
  const classes = useDropdownStyles();
  const theme = useTheme();
  const ref = useRef(null);

  const {
    unreadCount,
    notifications,
    hasNextPage,
    initialized,
    loading,
    initialize,
    loadMore,
    markNotificationsAsRead,
    markNotificationsAsUnread,
    deleteNotifications,
    deleteAllNotifications,
  } = useNotifications();
  const [isOpen, setOpen] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedMap, setSelectedMap] = useState({});
  const selectedCount = React.useMemo(
    () => Object.keys(selectedMap).length,
    [selectedMap]
  );

  // close dropdown whenever url changes
  const history = useHistory();
  history.listen(() => setOpen(false));

  const enterSelectMode = () => setSelectMode(true);
  const exitSelectMode = () => {
    setSelectMode(false);
    setSelectedMap({});
  };

  const onMasterSelectChange = () => {
    if (selectedCount === notifications.length) {
      setSelectedMap({});
    } else {
      const newMap = notifications.reduce(
        (obj, curr) => ({ ...obj, [curr.id]: true }),
        {}
      );
      setSelectedMap(newMap);
    }
  };
  const onSelectChangeFactory = React.useCallback((notificationId) => {
    const onSelectChange = (e) => {
      if (e.target.checked)
        setSelectedMap((prev) => ({ ...prev, [notificationId]: true }));
      else
        setSelectedMap((prev) => {
          const newMap = { ...prev };
          delete newMap[notificationId];
          return newMap;
        });
    };
    return onSelectChange;
  }, []);

  const markSelectedAsRead = async () => {
    if (!selectMode) return;
    const ok = await markNotificationsAsRead(Object.keys(selectedMap));
    if (ok) setSelectedMap({});
  };

  const deleteSelected = async () => {
    if (!selectMode) return;
    const ok = await deleteNotifications(Object.keys(selectedMap));
    if (ok) setSelectedMap({});
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
    initialize();
  }, [initialize]);

  const handleClose = useCallback(() => {
    setOpen(false);
    exitSelectMode();
  }, []);

  return (
    <>
      <Tooltip
        title={
          unreadCount
            ? `You have ${unreadCount} unread notifications`
            : 'You have no unread notifications'
        }
      >
        <IconButton
          color={!theme.palette.type === 'light' ? 'inherit' : undefined}
          onClick={handleOpen}
          ref={ref}
        >
          <Badge
            badgeContent={unreadCount || null}
            color="error"
            overlap="rectangular"
          >
            <SvgIcon fontSize="medium">
              <NotificationsIcon
                style={{ color: theme.palette.primary.main }}
              />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box className={classes.sticky}>
          <Box
            sx={{ px: 4, py: 2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.notificationIcon}
          >
            <Typography variant="h4" className={classes.notificationIcon}>
              Notifications
            </Typography>
            <IconButton
              className={classes.icon}
              color="primary"
              onClick={handleClose}
            >
              <SvgIcon>
                <XIcon className={classes.iconSvg} position="center" />
              </SvgIcon>
            </IconButton>
          </Box>

          {!!notifications.length && (
            <ListItem
              divider
              style={{
                display: 'flex',
                height: '60px',
                gridGap: '10px',
                background: theme.palette.background.paper,
              }}
            >
              {selectMode ? (
                <>
                  <FormControlLabel
                    style={{ marginLeft: '-15px', marginRight: 'auto' }}
                    control={
                      <Checkbox
                        onChange={onMasterSelectChange}
                        indeterminate={
                          selectedCount > 0 &&
                          selectedCount < notifications.length
                        }
                        checked={
                          selectedCount === notifications.length || false
                        }
                      />
                    }
                    label={`${selectedCount} selected`}
                  />
                  {!!selectedCount && (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={markSelectedAsRead}
                      >
                        Mark As Read
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={deleteSelected}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                  <IconButton onClick={exitSelectMode} title="Undo Select">
                    <XIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    onClick={deleteAllNotifications}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  >
                    Clear All
                  </Button>
                  <Button onClick={enterSelectMode} color="primary">
                    Select
                  </Button>
                </>
              )}
            </ListItem>
          )}
        </Box>

        {notifications.length === 0 ? (
          !!initialized && (
            <Box p={3}>
              <Typography variant="h6" color="primary">
                There are no notifications
              </Typography>
            </Box>
          )
        ) : (
          <List disablePadding>
            {notifications?.map((notification, rowIndex) => (
              <Fragment key={notification.id}>
                {hasNextPage && rowIndex === notifications.length - 3 && (
                  <Waypoint onEnter={loadMore} />
                )}
                <NotificationTile
                  key={notification.id}
                  notification={notification}
                  onDelete={() => deleteNotifications([notification.id])}
                  onRead={() => markNotificationsAsRead([notification.id])}
                  onUnread={() => markNotificationsAsUnread([notification.id])}
                  onSelect={onSelectChangeFactory(notification.id)}
                  selectMode={selectMode}
                  selected={selectedMap[notification.id] || false}
                />
              </Fragment>
            ))}
            {loading && (
              <ListItem>
                <CircularProgress size="1.5rem" style={{ margin: 'auto' }} />
              </ListItem>
            )}
          </List>
        )}
      </Popover>
    </>
  );
}

export default NotificationsDropdown;
