/**
 * Environment variables from .env file are
 * injected into index.html as json by Django.
 */

// Auth
export const GET_ENVS_URL = window.env.REACT_APP_GET_ENVS_URL;
// Sentry
export const APP_SENTRY_DSN = window.env.REACT_APP_SENTRY_DSN;
export const APP_SENTRY_ENV = window.env.ENV_NAME;
// Firebase
export const { FIREBASE_ENABLED, FIREBASE_CONFIG, FIREBASE_VAPID_PUBLIC_KEY } =
  window.env;

export const APP_VERSION = window.env.REACT_APP_VERSION;
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;
export const GRAPHQL_URL = window.env.REACT_APP_GRAPHQL_URL;
export const MAP_ACTIVE = window.env.REACT_APP_MAP_ACTIVE;
export const MAP_REPORT_ACTIVE = window.env.REACT_APP_MAP_REPORT_ACTIVE;
export const GAS_ACTIVE = window.env.REACT_APP_GAS_ACTIVE;
export const ELECTRIC_ACTIVE = window.env.REACT_APP_ELECTRIC_ACTIVE;
export const INVENTORY_ACTIVE = window.env.REACT_APP_INVENTORY_ACTIVE;
export const JOBS_ACTIVE = window.env.REACT_APP_JOBS_ACTIVE;
export const VANS_ACTIVE = window.env.REACT_APP_VANS_ACTIVE;
export const HOME_PAGE_URL = window.env.REACT_APP_HOME_PAGE_URL;
export const UMESH_API_DOMAIN = window.env.REACT_APP_UMESH_API_DOMAIN;
export const UMESH_WS_DOMAIN = window.location.host;
export const CUSTOMER_ID = window.env.REACT_APP_CUSTOMER_ID || 'dev';
export const IS_DEV = window.env.REACT_APP_IS_DEV;
export const FS_JOB_WORKSHEETS = window.env.REACT_APP_FS_JOB_WORKSHEETS;
