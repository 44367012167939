import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/assets/css/animations.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import {
  APP_SENTRY_DSN,
  APP_SENTRY_ENV,
  APP_VERSION,
  UMESH_API_DOMAIN,
} from './config';

Sentry.init({
  dsn: APP_SENTRY_DSN,
  environment: APP_SENTRY_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [UMESH_API_DOMAIN],
    }),
  ],
  tracesSampleRate: 1.0,
  release: `umesh-ui@${APP_VERSION}`,
});

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
