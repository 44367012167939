import {
  AccountTree as AccountTreeIcon,
  Category as CategoryIcon,
  InsertDriveFile as InsertDriveFileIcon,
  ListAlt as ListAltIcon,
  Map as MapIcon,
  Whatshot as WhatshotIcon,
} from '@material-ui/icons';
import DataDictionaryIcon from 'src/icons/DataDictionaryIcon';
import ManageAccountsIcon from 'src/icons/ManageAccountsIcon';

// eslint-disable-next-line import/prefer-default-export
export const WAYPOINT_ICON_COMPONENT = Object.freeze({
  ASSETS_MODULE: CategoryIcon,
  DD_MODULE: DataDictionaryIcon,
  FILES_MODULE: InsertDriveFileIcon,
  GAS_MODULE: WhatshotIcon,
  JOBS_MODULE: ListAltIcon,
  MAP_MODULE: MapIcon,
  MEM_MODULE: AccountTreeIcon,
  USERS_MODULE: ManageAccountsIcon,
});
