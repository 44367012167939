/** Helper function to download file from absolute url while remaining within app. */
const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
