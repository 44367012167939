import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { COLORS } from 'src/constants';
import { WideLogo } from 'src/components/Logo';
import Account from './Account';
import NotificationsDropdown from './NotificationsDropdown';
import PushNotification from './PushNotification';
import Settings from './Settings';
import useGenericNotifications from './useGenericNotifications';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.palette.type === 'light'
      ? {
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: theme.palette.colors.white,
        }
      : {}),
    ...(theme.palette.type === 'dark'
      ? {
          boxShadow: 'none',
          borderBottom: `1px solid ${COLORS.STORMY_INDIGO}`,
          backgroundColor: theme.palette.background.topBar,
        }
      : {}),
  },
  toolbar: {
    minHeight: 70,
  },
}));

function TopBar({ open, onDrawerToggle, ...rest }) {
  const classes = useStyles();
  useGenericNotifications();
  return (
    <AppBar className={classes.root} {...rest}>
      <PushNotification />
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton onClick={() => onDrawerToggle(!open)} edge="start">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <WideLogo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <NotificationsDropdown />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  open: PropTypes.bool,
  onDrawerToggle: PropTypes.func,
};

export default TopBar;
