import React from 'react';
import { SvgIcon } from '@material-ui/core';

function DataDictionaryIcon({ ...rest }) {
  return (
    <SvgIcon {...rest} viewBox="0 0 459.319 459.319" xmlSpace="preserve">
      <path d="M141.288 189.821h-23.685V145.28h23.528v-23.69h21.016v23.374h23.681v44.707h-23.681v.151h-20.859zM60.801 398.87c.012 1.1.03 1.921.042 2.459.006.331-.053.638-.071.963.739 18.224 15.755 32.817 34.146 32.817h303.76v-33.461c0-6.68 5.421-12.105 12.105-12.105 6.679 0 12.105 5.426 12.105 12.105v45.565c0 6.686-5.427 12.105-12.105 12.105H93.091c-.629 0-1.235-.089-1.847-.183-30.505-1.91-54.757-27.261-54.757-58.245 0-.71.085-1.396.109-2.099-.375-35.943 0-309.36.042-339.109-.047-.423-.127-.827-.127-1.259C36.511 26.205 62.719 0 94.938 0h315.833c6.685 0 12.105 5.423 12.105 12.105v342.139c0 6.679-5.421 12.104-12.105 12.104-.071 0-.13-.023-.201-.023-.887.213-1.82.354-2.772.354H94.918c-18.182-.005-33.059 14.269-34.117 32.191zm217.546-277.28h94.425v20.862h-23.685v23.69h-23.525v23.685h-23.69v23.533h-23.536v49.882h120.792v-26.209h-94.584v-20.859h23.69V192.49h23.525V168.8h23.689v-23.52h23.679V95.237h-120.78v26.353zm-53.398 70.9h56.265v-26.353h-56.265v26.353zM91.41 263.229h26.2v-47.056h23.679v-.15h44.54v47.219h26.354v-120.79h-23.688v-23.841H164.81V95.237h-26.202v23.684h-23.685v23.69H91.389v120.618h.021z" />
    </SvgIcon>
  );
}

export default DataDictionaryIcon;
