import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import ModalContainer from 'react-modal-promise';
import * as Sentry from '@sentry/react';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import CustomApolloProvider from './CustomApolloProvider';
import DefaultNotistackContent from './components/notistack/DefaultNotistackContent';
import SuccessNotistackContent from './components/notistack/SuccessNotistackContent';
import FirebaseCloudMessaging from './components/FirebaseCloudMessaging';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

const CustomThemeProvider = ({ children }) => {
  const { settings } = useSettings();
  return (
    <ThemeProvider theme={createTheme(settings)}>{children}</ThemeProvider>
  )
};

function App() {
  useStyles();
  return (
    <Router history={history}>
      <Auth>
        <FirebaseCloudMessaging />
        <CustomApolloProvider>
          <CustomThemeProvider>
            <StylesProvider jss={jss}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider
                  maxSnack={1}
                  Components={{
                    success: SuccessNotistackContent,
                    error: DefaultNotistackContent,
                    info: DefaultNotistackContent,
                    warning: DefaultNotistackContent,
                    default: DefaultNotistackContent,
                  }}
                >
                  <ModalContainer />
                  <ScrollReset />
                  <CookiesNotification />
                  <SettingsNotification />
                  <Routes />
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </CustomThemeProvider>
        </CustomApolloProvider>
      </Auth>
    </Router>

  );
}

export default Sentry.withProfiler(App);
