import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'src/components/uAuth';

function GuestGuard({ children }) {
  const { authService } = useAuth();

  if (authService.isAuthenticated() && !authService.isTokenExpired()) {
    return <Redirect to="/app" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any,
};

export default GuestGuard;
