import React from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import { useHistory } from 'react-router';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import {
  Close as CloseIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import downloadFile from 'src/utils/downloadFile';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
    gridTemplateRows: '36px 36px',
    backgroundColor: theme.palette.notification.background,
    gap: theme.spacing(1.5),
    padding: '2px 10px 2px 10px',
    borderRadius: '18px',
  },
  logo: {
    gridColumn: '1/2',
    gridRow: '1/3',
    alignSelf: 'center',
    maxWidth: '40px',
  },
  color: {
    color: theme.palette.notification.color,
  },
  title: {
    gridColumn: '2/3',
    gridRow: '1/2',
    alignSelf: 'end',
  },
  body: {
    gridColumn: '2/3',
    gridRow: '2/3',
    alignSelf: 'start',
  },
  btn: {
    maxHeight: '25px',
    maxWidth: '25px',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  closeBtn: {
    gridColumn: '3/3',
    gridRow: '1/2',
    alignSelf: 'start',
  },
  settingsBtn: {
    gridColumn: '3/3',
    gridRow: '2/3',
    alignSelf: 'end',
  },
}));

const PushNotificationContent = React.forwardRef((props, ref) => {
  const { id, message, title, actions } = props; // eslint-disable-line react/prop-types
  const classes = useStyles();
  const history = useHistory();
  const { closeSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  const dismiss = () => closeSnackbar(id);

  const onClickClose = (e) => {
    e.stopPropagation();
    dismiss();
  };

  const onClickSettings = (e) => {
    e.stopPropagation();
    history.push({
      pathname: `/app/users/${account.user.id}`,
      search: '?view=notifications',
    });
  };

  const action = actions?.[0];

  const onClickSnackbar = () => {
    if (!action) return;

    if (action.type === 'redirect') {
        history.push(action.content);
        dismiss();
    } else if (action.type === 'download') {
        downloadFile(action.content);
        dismiss();
    }
};

  return (
    <SnackbarContent ref={ref}>
      <Box className={classes.container} onClick={onClickSnackbar}>
        <img
          alt="Logo"
          className={classes.logo}
          src="/static/images/icon-logo-midnight.png"
        />
        <Typography
          className={[classes.color, classes.title]}
          variant="h5"
          component="h5"
        >
          {title}
        </Typography>
        <Typography
          className={[classes.color, classes.body]}
          variant="subtitle2"
        >
          {message}
        </Typography>
        <IconButton
          className={[classes.btn, classes.color, classes.closeBtn]}
          onClick={onClickClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          className={[classes.btn, classes.color, classes.settingsBtn]}
          size="small"
          onClick={onClickSettings}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
});

export default PushNotificationContent;
