import React, { useContext } from 'react';

export const AuthContext = React.createContext(undefined);
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
export function withAuth(ComponentToWrap) {
  const WrappedComponent = (props) => {
    const authProps = useAuth();
    return React.createElement(ComponentToWrap, { ...authProps, ...props });
  };
  WrappedComponent.displayName = `withAuth_${
    ComponentToWrap.displayName || ComponentToWrap.name
  }`;
  return WrappedComponent;
}
