import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useSubscription, gql } from '@apollo/client';
import PushNotificationContent from '../../../components/notistack/PushNotificationContent';

const PUSH_NOTIFICATIONS = gql`
  subscription {
    pushNotification {
      activityId
      content
      name
      actions {
        content
        type
      }
    }
  }
`;

const PushNotificationHandler = () => {
  const { enqueueSnackbar } = useSnackbar();

  useSubscription(PUSH_NOTIFICATIONS, {
    context: { clientName: 'strawberry' },
    onData: ({ data: { data } }) => {
      const { pushNotification } = data;
      enqueueSnackbar(pushNotification.content, {
        persist: true,
        variant: 'push',
        title: pushNotification.name,
        actions: pushNotification.actions,
      });
    },
  });

  return null;
};

const PushNotification = () => {
  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      hideIconVariant
      Components={{
        push: PushNotificationContent,
      }}
    >
      <PushNotificationHandler />
    </SnackbarProvider>
  );
};

export default PushNotification;
