import React from 'react';
import gql from 'graphql-tag';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription,
} from '@apollo/client';

const GET_NOTIFICATIONS_COUNT = gql`
  query GetNotificationsCounts {
    notifications {
      totalCount
      unreadCount
    }
  }
`;

const GET_NOTIFICATIONS = gql`
  query GetInitialNotifications($first: Int, $after: String, $before: String) {
    results: notifications(
      pagination: { first: $first, after: $after, before: $before }
    ) {
      edges {
        node {
          id
          read
          iconType
          activity {
            actor {
              content
              id
              url
            }
            object {
              content
              id
              url
            }
            target {
              content
              id
              url
            }
            timestamp
            content
            mediaType
            name
          }
          actions {
            content
            title
            type
          }
        }
      }
      totalCount
      unreadCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const DELETE_NOTIFICATIONS = gql`
  mutation ($ids: [ID!]!) {
    ok: deleteNotifications(ids: $ids)
  }
`;

const DELETE_ALL_NOTIFICATIONS = gql`
  mutation {
    ok: deleteAllNotifications
  }
`;

const MARK_AS_READ = gql`
  mutation ($ids: [ID!]!) {
    ok: markNotificationsAsRead(ids: $ids)
  }
`;

const MARK_AS_UNREAD = gql`
  mutation ($ids: [ID!]!) {
    ok: markNotificationsAsUnread(ids: $ids)
  }
`;

const SUBSCRIBE_TO_NEW_NOTIFICATIONS = gql`
  subscription {
    newNotification {
      id
      read
      iconType
      activity {
        actor {
          content
          id
          url
        }
        object {
          content
          id
          url
        }
        target {
          content
          id
          url
        }
        timestamp
        content
        mediaType
        name
      }
      actions {
        content
        title
        type
      }
    }
  }
`;

const getNodes = (data) => data.results.edges.map((e) => e.node) || [];

const initialState = {
  notifications: [],
  totalCount: 0,
  unreadCount: 0,
  hasNextPage: null,
  endCursor: null,
};

export default () => {
  const [initialized, setInitialized] = React.useState(false);
  const [state, setState] = React.useState(initialState);

  const [getNotifications, { loading: loadingNotifications }] = useLazyQuery(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: 'no-cache',
      context: { clientName: 'strawberry' },
    }
  );

  const { loading: loadingCount } = useQuery(GET_NOTIFICATIONS_COUNT, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'strawberry' },
    onCompleted: ({ notifications: { totalCount, unreadCount } }) =>
      setState((prev) => ({
        ...prev,
        totalCount,
        unreadCount,
      })),
  });

  const [deleteMutation] = useMutation(DELETE_NOTIFICATIONS, {
    context: { clientName: 'strawberry' },
  });
  const [deleteAllMutation] = useMutation(DELETE_ALL_NOTIFICATIONS, {
    context: { clientName: 'strawberry' },
  });
  const [markAsReadMutation] = useMutation(MARK_AS_READ, {
    context: { clientName: 'strawberry' },
  });
  const [markAsUnreadMutation] = useMutation(MARK_AS_UNREAD, {
    context: { clientName: 'strawberry' },
  });

  const initialize = React.useCallback(() => {
    if (initialized) return;
    getNotifications({
      variables: { first: 10 },
      onCompleted: (data) => {
        if (data.results) {
          setState({
            notifications: [...state.notifications, ...getNodes(data)],
            totalCount: data.results.totalCount,
            unreadCount: data.results.unreadCount,
            ...data.results.pageInfo,
          });
        }
        setInitialized(true);
      },
    });
  }, [getNotifications, setState, setInitialized, initialized]);

  const loadMore = React.useCallback(() => {
    if (!state.hasNextPage || loadingNotifications) return;
    getNotifications({
      variables: { first: 10, after: state.endCursor },
      onCompleted: (data) => {
        if (data.results) {
          setState({
            notifications: [...state.notifications, ...getNodes(data)],
            totalCount: data.results.totalCount,
            unreadCount: data.results.unreadCount,
            ...data.results.pageInfo,
          });
        }
      },
    });
  }, [getNotifications, state, setState, loadingNotifications]);

  const deleteNotifications = React.useCallback(
    async (ids) => {
      const {
        data: { ok },
      } = await deleteMutation({ variables: { ids } });
      if (!ok) return false;

      let { unreadCount } = state;
      const notifications = state.notifications.filter((obj) => {
        if (ids.includes(obj.id)) {
          if (!obj.read) unreadCount--;
          return false;
        }
        return true;
      });

      setState((prevState) => ({
        ...prevState,
        notifications,
        totalCount: prevState.totalCount - ids.length,
        unreadCount,
      }));
      return true;
    },
    [deleteMutation, state]
  );

  const deleteAllNotifications = React.useCallback(async () => {
    const {
      data: { ok },
    } = await deleteAllMutation();
    if (!ok) return false;

    setState({ ...initialState });
    return true;
  }, [deleteAllMutation]);

  const markNotificationsAsRead = React.useCallback(
    async (ids) => {
      const {
        data: { ok },
      } = await markAsReadMutation({ variables: { ids } });
      if (!ok) return false;

      const notifications = state.notifications.map((obj) => {
        if (ids.includes(obj.id)) {
          return { ...obj, read: true };
        }
        return obj;
      });

      const unreadCount = state.notifications.filter(
        (obj) => !obj.read && ids.includes(obj.id)
      ).length;

      setState({
        ...state,
        notifications,
        unreadCount: state.unreadCount - unreadCount,
      });
      return true;
    },
    [markAsReadMutation, state]
  );

  const markNotificationsAsUnread = React.useCallback(
    async (ids) => {
      const {
        data: { ok },
      } = await markAsUnreadMutation({ variables: { ids } });
      if (!ok) return false;

      const notifications = state.notifications.map((obj) => {
        if (ids.includes(obj.id)) {
          return { ...obj, read: false };
        }
        return obj;
      });
      setState({
        ...state,
        notifications,
        unreadCount: state.unreadCount + ids.length,
      });
      return true;
    },
    [markAsUnreadMutation, state]
  );

  useSubscription(SUBSCRIBE_TO_NEW_NOTIFICATIONS, {
    context: { clientName: 'strawberry' },
    onData: ({ data: { data } }) => {
      const { newNotification } = data;
      setState((prev) => ({
        ...prev,
        notifications: [newNotification, ...prev.notifications],
        unreadCount: newNotification.read
          ? prev.unreadCount
          : prev.unreadCount + 1,
      }));
    },
  });

  return {
    ...state,
    loading: loadingNotifications || loadingCount,
    initialize,
    initialized,
    loadMore,
    deleteNotifications,
    deleteAllNotifications,
    markNotificationsAsRead,
    markNotificationsAsUnread,
  };
};
