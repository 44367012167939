/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';

export const AuthProvider = (props) => {
  const { authService, children } = props;
  return React.createElement(
    AuthContext.Provider,
    { value: { authService } },
    children
  );
};

AuthProvider.propTypes = {
  authService: PropTypes.any,
  children: PropTypes.node,
};
