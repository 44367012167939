import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { setDisabledExporters } from 'src/actions/accountActions';

const GET_GENERIC_NOTIFICATIONS = gql`
  query GetGenericNotification {
    genericNotifications {
      type
      data
    }
  }
`;

const SUBSCRIBE_TO_GENERIC_NOTIFICATIONS = gql`
  subscription {
    genericNotification {
      type
      data
    }
  }
`;

const handlers = {
  export_file: ({ data, dispatch }) => {
    dispatch(setDisabledExporters(data));
  },
};

export default () => {
  const dispatch = useDispatch();

  const { data: notificationData } = useQuery(GET_GENERIC_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'strawberry' },
  });

  const handleNotification = (notification) => {
    const handler = handlers[notification.type];
    if (handler) {
      handler({ data: JSON.parse(notification.data), dispatch });
    }
  };

  useSubscription(SUBSCRIBE_TO_GENERIC_NOTIFICATIONS, {
    context: { clientName: 'strawberry' },
    onData: (data) => {
      const notification = data.data.data.genericNotification;
      handleNotification(notification);
    },
  });

  useEffect(() => {
    if (!notificationData?.genericNotifications) return;
    notificationData.genericNotifications.map(handleNotification);
  }, [notificationData]);
};
