import React from 'react';
import {
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import useSettings from 'src/hooks/useSettings';
import { COLORS, THEMES } from 'src/constants';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness2SharpIcon from '@material-ui/icons/Brightness2Sharp';

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    color:
      theme.palette.type === 'light' ? COLORS.SLATE_GREY : COLORS.LAVENDER_GREY,
  },
}));

function Settings() {
  const { settings, saveSettings } = useSettings();
  const classes = useStyles();
  const theme = useTheme();

  const handleThemeSwitch = () => {
    const newTheme =
      theme.palette.type === 'light' ? THEMES.DARK : THEMES.LIGHT;
    saveSettings({ ...settings, theme: newTheme });
  };

  return (
    <Tooltip
      title={
        theme.palette.type === 'light'
          ? 'Turn Dark Mode On'
          : 'Turn Light Mode On'
      }
    >
      <IconButton onClick={handleThemeSwitch}>
        <SvgIcon className={classes.iconStyle} fontSize="medium">
          {theme.palette.type === 'light' ? (
            <Brightness2SharpIcon />
          ) : (
            <Brightness4Icon />
          )}
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default Settings;
