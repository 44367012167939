import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'src/components/uAuth';

function AuthGuard({ children }) {
  const { authService } = useAuth();
  if (!authService.isAuthenticated() || authService.isTokenExpired()) {
    authService.clear();
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
