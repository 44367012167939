import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';

export const DEFAULT_WIDELOGO_HEIGHT = 36;

const useStyles = makeStyles((theme) => ({
  logo: {
    backgroundColor: theme.palette.background.logo,
    width: '90px',
    borderRadius: '5px',
    padding: '4px',
    marginRight: theme.spacing(2),
  },
}));

function Logo(props) {
  const classes = useStyles();
  const logoSrc = '/static/images/logo-waypoint.png';

  return <img alt="Logo" className={classes.logo} src={logoSrc} {...props} />;
}

function WideLogo({ height = DEFAULT_WIDELOGO_HEIGHT, ...props }) {
  const theme = useTheme();
  const logoSrc =
    theme.palette.type === 'light'
      ? '/static/images/logo-waypoint-wide-midnight.png'
      : '/static/images/logo-waypoint-wide-white.png';

  return <img alt="Logo" style={{ height }} src={logoSrc} {...props} />;
}

WideLogo.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  height: PropTypes.number,
};

export { Logo, WideLogo };
