import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { COLORS } from 'src/constants';
/* eslint-disable no-nested-ternary */
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(14),
    right: theme.spacing(3),
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : COLORS.DARK_GREY,
    color: '#fff',
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.light
          : COLORS.GREY,
    },
  },
}));

function ScrollToTop({ element }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const onScroll = () => {
    setVisible(element.scrollTop > 15);
  };

  useEffect(() => {
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  }, [element]);

  if (!visible) {
    return null;
  }

  return (
    <IconButton
      className={classes.root}
      onClick={() => {
        element.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <VerticalAlignTopIcon />
    </IconButton>
  );
}

ScrollToTop.propTypes = {
  element: PropTypes.any,
};

export default ScrollToTop;
