import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton } from '@material-ui/core';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { COLORS } from 'src/constants';
/* eslint-disable no-nested-ternary */
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(3),
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.main
        : COLORS.DARK_GREY,
    color: '#fff',
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.light
          : COLORS.GREY,
    },
  },
}));

function ScrollToBottom({ element }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const onScroll = () => {
    setVisible(element.scrollTop > 15);
  };

  useEffect(() => {
    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  }, [element]);

  if (!visible) {
    return null;
  }

  return (
    <IconButton
      className={classes.root}
      onClick={() => {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth',
        });
      }}
    >
      <VerticalAlignBottomIcon />
    </IconButton>
  );
}

ScrollToBottom.propTypes = {
  element: PropTypes.any,
};

export default ScrollToBottom;
