import React from 'react';
import PropTypes from 'prop-types';
import { AuthProvider } from 'src/components/uAuth';
import '../assets/css/auth.css';
import authService from '../services/AuthService';

function Auth({ children }) {
  return <AuthProvider authService={authService}>{children}</AuthProvider>;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
