/* eslint-disable */
// TODO-ESLINT
import jwtDecode from 'jwt-decode';
import { createPKCECodes } from './pkce';
import { toUrlEncoded } from './util';
import { UMESH_API_DOMAIN } from 'src/config';


export class AuthService {
  constructor(props) {
    this.props = props;
    const code = this.getCodeFromLocation(window.location);
    if (code !== null) {
      this.fetchToken(code)
        .then(() => {
          this.restoreUri();
        })
        .catch((e) => {
          this.removeItem('pkce');
          this.removeItem('auth');
          this.removeCodeFromLocation();
          console.warn({ e });
        });
    } else if (this.props.autoRefresh) {
      this.startTimer();
    }
  }

  getUser() {
    const t = this.getAuthTokens();
    if (t === null) return {};
    const decoded = jwtDecode(t.token);
    return decoded;
  }

  getCodeFromLocation(location) {
    const split = location.toString().split('?');
    if (split.length < 2) {
      return null;
    }
    const pairs = split[1].split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key === 'code') {
        return decodeURIComponent(value || '');
      }
    }
    return null;
  }

  removeCodeFromLocation() {
    const [base, search] = window.location.href.split('?');
    if (!search) {
      return;
    }
    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&');
    window.history.replaceState(
      window.history.state,
      'null',
      base + (newSearch.length ? `?${newSearch}` : '')
    );
  }

  getItem(key) {
    return window.localStorage.getItem(key);
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  getPkce() {
    const pkce = window.localStorage.getItem('pkce');
    if (pkce === null) {
      throw new Error('PKCE pair not found in local storage');
    } else {
      return JSON.parse(pkce);
    }
  }

  setAuthTokens(auth) {
    const { exp } = jwtDecode(auth.token);
    auth.expires_at = exp * 1000;
    window.localStorage.setItem('auth', JSON.stringify(auth));
  }

  getAuthTokens() {
    return JSON.parse(window.localStorage.getItem('auth') || '{}');
  }

  isPending() {
    return (
      window.localStorage.getItem('pkce') !== null &&
      window.localStorage.getItem('auth') === null
    );
  }

  isAuthenticated() {
    return window.localStorage.getItem('auth') !== null;
  }

  isTokenExpired() {
    const { expires_at } = this.getAuthTokens();
    const now = new Date().getTime();
    return !expires_at || now >= expires_at;
  }

  clear() {
    this.removeItem('pkce');
    this.removeItem('auth');
    this.removeItem('clientId');
    this.removeItem('authorizeEndpoint');
    this.removeItem('tokenEndpoint');
  }

  async logout() {

    const { token, refresh } = this.getAuthTokens();
    const authURL = `${window.location.protocol}//${UMESH_API_DOMAIN.replace('backend.', 'auth.')}/o/jwt-logout`;
    const url = authURL
    const that = this;
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ refresh_token: refresh }),
    }).then(() => {
      that.clear();
      window.location.reload();

    });

    return true;
  }

  async login() {
    console.log('login');
    this.authorize();
  }

  // this will do a full page reload and to to the OAuth2 provider's login page and then redirect back to redirectUri
  authorize() {
    const clientId = window.localStorage.getItem('clientId');
    const authorizeEndpoint = window.localStorage.getItem('authorizeEndpoint');
    const redirectUri = `${window.location.origin}`;
    const pkce = createPKCECodes();
    window.localStorage.setItem('pkce', JSON.stringify(pkce));
    window.localStorage.setItem('preAuthUri', location.href);
    window.localStorage.removeItem('auth');
    const { codeChallenge } = pkce;
    const pkceState = pkce.state;
    const query = {
      clientId,
      responseType: 'code',
      state: pkceState,
      redirectUri,
      codeChallenge,
      codeChallengeMethod: 'S256',
    };
    // Responds with a 302 redirect
    const url = `${authorizeEndpoint}?${toUrlEncoded(query)}`;
    window.location.replace(url);
    return true;
  }

  // this happens after a full page reload. Read the code from localstorage
  async fetchToken(code, isRefresh = false) {
    const { autoRefresh = true } = this.props;

    const clientId = window.localStorage.getItem('clientId');
    const tokenEndpoint = window.localStorage.getItem('tokenEndpoint');
    const redirectUri = `${window.location.origin}`;
    const grantType = 'authorization_code';
    let payload = {
      clientId,
      redirectUri,
      grantType,
    };
    if (isRefresh) {
      payload = { refresh: code };
    } else {
      const pkce = this.getPkce();
      const { codeVerifier } = pkce;
      payload = {
        ...payload,
        code,
        codeVerifier,
      };
    }
    const response = await fetch(`${tokenEndpoint}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: toUrlEncoded(payload),
    });
    this.removeItem('pkce');
    const json = await response.json();
    this.setAuthTokens(json);
    if (autoRefresh) {
      this.startTimer();
    }
    return this.getAuthTokens();
  }

  armRefreshTimer(refreshToken, timeoutDuration) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(() => {
      this.fetchToken(refreshToken, true)
        .then(({ refresh: newRefreshToken, expires_at: expiresAt }) => {
          if (!expiresAt) return;
          const now = new Date().getTime();
          const timeout = expiresAt - now;
          if (timeout > 0) {
            this.armRefreshTimer(newRefreshToken, timeout);
          } else {
            this.removeItem('auth');
            this.removeCodeFromLocation();
          }
        })
        .catch((e) => {
          this.removeItem('auth');
          this.removeCodeFromLocation();
          console.warn({ e });
        });
    }, timeoutDuration);
  }

  startTimer() {
    const authTokens = this.getAuthTokens();
    if (!authTokens) {
      return;
    }
    const { refresh: refreshToken, expires_at: expiresAt } = authTokens;
    if (!expiresAt || !refreshToken) {
      return;
    }
    const now = new Date().getTime();
    const timeout = expiresAt - now;
    if (timeout > 0) {
      this.armRefreshTimer(refreshToken, timeout);
    } else {
      this.removeItem('auth');
      this.removeCodeFromLocation();
    }
  }

  restoreUri() {
    const uri = window.localStorage.getItem('preAuthUri');
    window.localStorage.removeItem('preAuthUri');
    if (uri !== null) {
      window.location.replace(uri);
    }
    this.removeCodeFromLocation();
  }
}
