import axios from 'axios';
import { batch } from 'react-redux';
import { UMESH_API_DOMAIN } from '../config';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const REFRESH_TOKEN = '@account/refresh-token';
export const LOAD_PERMISSIONS = '@account/load-permissions';
export const RESET_PERMISSIONS = '@account/reset-permissions';
export const EXPORTER_NOTIFICATION = '@account/exporter-notification';

export function setUserData(user, token) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
        token,
      },
    });
}

export function setUserToken(token) {
  return (dispatch) =>
    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        token,
      },
    });
}

export function updateProfile(user) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_PROFILE,
      payload: { user },
    });
}

export function loadPerms(perms) {
  return (dispatch) =>
    dispatch({
      type: LOAD_PERMISSIONS,
      payload: {
        perms,
      },
    });
}

export function clearPerms() {
  return (dispatch) =>
    dispatch({
      type: LOAD_PERMISSIONS,
      payload: {
        perms: [],
      },
    });
}

export function resetPerms() {
  return (dispatch) =>
    dispatch({
      type: RESET_PERMISSIONS,
    });
}

export function fetchPerms() {
  return (dispatch) => {
    const { token } = JSON.parse(window.localStorage.getItem('auth')) || {};

    if (!token) {
      // if there is no token, assume no user is logged in
      dispatch(clearPerms());
      return undefined;
    }
    return axios(
      `${window.location.protocol}//${UMESH_API_DOMAIN}/api/v1/users/me/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        const { portal_permissions, user_permissions, ...profile } =
          res.data || {};
        batch(() => {
          dispatch(loadPerms(portal_permissions));
          dispatch(updateProfile(profile));
        });
      })
      .catch(() => {
        dispatch(clearPerms());
      });
  };
}

export function setDisabledExporters(exporter) {
  return (dispatch) =>
    dispatch({
      type: EXPORTER_NOTIFICATION,
      payload: exporter,
    });
}
