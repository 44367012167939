import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { useAuth } from 'src/components/uAuth';
import { COLORS } from 'src/constants';
import { useSelector } from 'react-redux';

/* eslint-disable no-nested-ternary */
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 45,
    width: 45,
    marginRight: theme.spacing(1),
    ...(theme.palette.type === 'light'
      ? {
          '&.MuiAvatar-colorDefault': {
            backgroundColor: COLORS.SLATE_GREY,
            color: COLORS.WHITE,
          },
        }
      : {
          '&.MuiAvatar-colorDefault': {
            backgroundColor: theme.palette.primary.main,
            color: COLORS.WAYPOINT_DARK,
          },
        }),
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const { authService } = useAuth();
  const logout = async () => authService.logout();
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const user = useSelector((state) => state.account.user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={null} />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="primary"
            style={{ paddingRight: '20px' }}
          >
            {`${user.first_name} ${user.last_name}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account">
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
