/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  REFRESH_TOKEN,
  LOAD_PERMISSIONS,
  RESET_PERMISSIONS,
  EXPORTER_NOTIFICATION
} from 'src/actions/accountActions';

const initialState = {
  user: null,
  disabledExporters: {},
  perms: {
    loaded: false,
    perms: [],
  },
};

// eslint-disable-next-line default-param-last
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.token = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user, token } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.token = token;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.token = null;
      });
    }

    case SILENT_LOGIN: {
      const { user, token } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.token = token;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;
      return produce(state, (draft) => {
        draft.user = { ...state.user, ...user };
      });
    }

    case REFRESH_TOKEN: {
      const { token } = action.payload;
      return produce(state, (draft) => {
        draft.token = token;
      });
    }

    case LOAD_PERMISSIONS: {
      const perms = action.payload.perms || [];
      return produce(state, (draft) => {
        draft.perms = { ...state.perms, loaded: true, perms };
      });
    }

    case RESET_PERMISSIONS: {
      return produce(state, (draft) => {
        draft.perms = { ...initialState.perms };
      });
    }

    case EXPORTER_NOTIFICATION: {
      return produce(state, (draft) => {
        draft.disabledExporters = { ...state.disabledExporters,  [action.payload.exporter_id]: action.payload.disabled};
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
